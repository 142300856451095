import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import counterStrike from "../images/counter-strike.svg"
import fortnite from "../images/FortniteLogoWhite.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Tilmelding" />
    <section>
      <h1>Tilmeld dig her!</h1>
      <p>
        Skal du med på holdet? Så se længere nede!<br />
        Vores år er delt op i 1. halvår og 2. halvår.<br /><br />
        Har du lyst til at se en træning før du tilmelder dig?<br />
        Vi tilbyder én gratis trænings session, så kom forbi når vi træner!
      </p>
    </section>
    <section>
      <div className="signup-wrapper">
        <div className="signup-card hidden">
          <div className="game-header">
            <img src={counterStrike} alt="Counter-Strike" />
          </div>
          <div className="content">
            <span className="activity">Forår</span>
            <span className="date">
              21. Januar 2020
              {' - '}
              20. Juni 2020
            </span>
            <div className="schedule">
              <div className="day-col">
                <div className="day">
                  Tirsdag
                </div>
                <div className="time">
                  18:00-20:00
              </div>
              </div>
              <div className="day-col">
                <div className="day">
                  Torsdag
                </div>
                <div className="time">
                  18:00-20:00
              </div>
              </div>
            </div>
            <div className="price">
              Pris: 500
            </div>
          </div>
          <div className="button-wrapper">
            <a href="https://www.conventus.dk/dataudv/www/new_tilmelding.php?foreningsid=13880&gruppe=636566&skjul_nyt_medlem=0&skjul_allerede_medlem=0&sprog=da" target="_blank" className="signup-button" rel="noopener noreferrer">
              <div className="signup-text">
                  Tilmeld
              </div>
            </a>
          </div>
        </div>
        <div className="signup-card">
          <div className="game-header">
            <img src={counterStrike} alt="Counter-Strike" />
          </div>
          <div className="content">
            <span className="activity">Efterår</span>
            <span className="date">
              18. August 2020
              {' - '}
              17. December 2020
            </span>
            <div className="schedule">
              <div className="day-col">
                <div className="day">
                  Tirsdag
                </div>
                <div className="time">
                  18:00-20:00
              </div>
              </div>
              <div className="day-col">
                <div className="day">
                  Torsdag
                </div>
                <div className="time">
                  18:00-20:00
              </div>
              </div>
            </div>
            <div className="price">
              Pris: 500
            </div>
          </div>
          <div className="button-wrapper">
            <a href="https://www.conventus.dk/dataudv/www/new_tilmelding.php?foreningsid=13880&gruppe=624826&skjul_nyt_medlem=0&skjul_allerede_medlem=0&sprog=da" target="_blank" className="signup-button" rel="noopener noreferrer">
              <div className="signup-text">
                  Tilmeld
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
